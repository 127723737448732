import styled from "styled-components"

const MainConnectButton = styled.div`
  background: #0069b7;
  border: none;
  color: white;
  max-width: 400px;
  padding: .5em;
  border-radius: 45px;
  font-weight: 600;
  margin: 1em 0;
`
const SecondaryBlueButton = styled.button`
  background: #0069b7;
  border: 0;
  color: white;
  margin-top: 1em;
  padding: .5em 2.5em;
  border-radius: 45px;
  max-width: 300px;
  margin-bottom:2em;
`

const ConectarseButton = styled.div`
  background: white;
  width: 100px;
  margin: 0 auto;
  border-radius: 45px;
  color: #0069b7;
  padding: .25em 1em;
  font-weight: 700;
`

const FbButton = styled.div`
  background:#225499;
  display:flex;
  justify-content: space-around;
  color: white;
  border-radius: 45px;
  margin: 1.5em 0;
`

const GoogleButton = styled.div`
  display:flex;
  justify-content: space-around;
  color: grey;
  border:1px solid #9b9b9b;
  border-radius: 45px;
  margin: 1.5em 0;
`

const C360Button = styled.div`
  display:flex;
  justify-content: space-around;
  color: #1acaf6;
  border:1px solid #1acaf6;
  border-radius: 45px;
  margin: 1.5em 0;
`
export {
  MainConnectButton,
  SecondaryBlueButton,
  ConectarseButton,
  FbButton,
  GoogleButton,
  C360Button
}