import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MaskedInput from 'react-maskedinput'
import styled from "styled-components"

import { SMSCambiarPassword, SMSVerificarCodigo, cambiarPassword } from "../helpers/api"
import { Layout } from "./Layout"
import Button from "./Button"
import { InputItem, DatosInvalidos  } from "../styles/formStyles"

const HelperText = styled.p`
  margin-bottom:2em;
`

function PasswordChange(props) {
  const [ formStatus, setFormStatus ] = useState("DNICHECK")
  const [ emptyTextError, setEmptyTextError ] = useState()
  const [ resetFailed, setResetFailed ] = useState()
  const [ resetSuccess, setResetSuccess ] = useState(false)
  const [ passwordLengthError, setPasswordLengthError ] = useState()
  const [ wrongPassword, setWrongPassword ] = useState(false)
  // const [ smsCode, setSMSCode ] = useState("")
  
  const [ state, setState ] = useState({
    dni: "",
    password: "",
    passwordRepeat: "",
    fechaNacimiento:false,
    smsCode: ""
  })
  let history = useHistory();
  

  useEffect(() => {
    //GET PARAMS FROM URL AND SAVE IT IN CONTEXT PROBABLY
    
    const url_string = new URL(window.location.href)
    const url = process.env.REACT_APP_URL ? new URL(process.env.REACT_APP_URL) : new URL(url_string.href)
    let searchParams = new URLSearchParams(url.search)
    let token = searchParams.get("token") 
    // setHashRequest(token)   
    },[])


  const handleInput = (e) => {
    const { name, value } = e.target
    setEmptyTextError(false)
    setPasswordLengthError(false)
    setResetFailed(false)
    setWrongPassword(false)
    setState( prevState => ({
      ...prevState,
      [name]:value
    }))
  }

  const handleCheckDNI = () => {
    //endpoint checkDNI
    //on success
    if(state.dni.length != 0 && state.fechaNacimiento != false ) {
      setFormStatus("VALIDATESMS")
      setEmptyTextError(false)
    } else {
      setEmptyTextError(true)
    }
  }

  const handleValidateSMS = () => {
    //endpoint
    //on success 
    if(state.smsCode.length != 0) {
      setFormStatus("CHANGEPASSWORD")
      setEmptyTextError(false)
    } else {
      setEmptyTextError(true)
    }
  }

  function handleChangePassword() {
    if(validatePassword() && validatePasswordMatch()) {
      alert("Cambiaste la contrasena con exito")
      history.push(`/`)
    }
  }

  function validatePassword() {
    let passwordIsValid = true
    if(state.password.length > 0 && state.password.length < 6) {
      passwordIsValid = false
      setPasswordLengthError(true)
    }
    console.log("passwordIsValid", passwordIsValid)

    return passwordIsValid
  }

  const validatePasswordMatch = () => {
    let passwordsValid = true
    if(state.password.length < 1 || state.passwordRepeat.length < 1) {
      setEmptyTextError(true);
      setWrongPassword(false)
      passwordsValid = false;
    }
    if(state.password.length > 1 && state.passwordRepeat.length > 1 && state.password !== state.passwordRepeat) {
      passwordsValid = false;
      setWrongPassword(true);
      setEmptyTextError(false);
    }
    console.log("validatePasswordMatch", passwordsValid)

    return passwordsValid;
  }
  return(
    <>
    <Layout title={"BIENVENIDOS"} login={true}>
      {formStatus === "DNICHECK" && (
      <div>
        <HelperText>Para modificar tu contraseña, <br></br> por favor ingresá tu DNI</HelperText>
        <InputItem>
          DNI
          <input type="number"  name="dni" onChange={handleInput} value={state.dni}/>
        </InputItem>
        <InputItem>
          FECHA DE NACIMIENTO
          <MaskedInput mask="11/11/1111" name="fechaNacimiento" placeholder="dd/mm/yyyy" onChange={handleInput}/>
        </InputItem>
        <Button mainConnect onClick={handleCheckDNI}>Continuar</Button>
      </div>
      )}
      {formStatus === "VALIDATESMS" && (
        <div>
          <HelperText>Te envíamos un mensaje de texto con las instrucciones para cambiar la contraseña</HelperText>
          <InputItem>
            Código de SMS
            <input onChange={handleInput} type="number" name="smsCode" required="" data-validation="required" placeholder="Código" pattern="[0-9]*" maxlength="4"/>
          </InputItem>
          <Button mainConnect onClick={handleValidateSMS}>Verificar</Button>
        </div>
      )}
      {formStatus === "CHANGEPASSWORD" && (
        <div>
          <HelperText>Porfavor ingrese la nueva contraseña</HelperText>
          <InputItem>
            NUEVA CONSTRASEÑA
            <input type="password"  name="password" onChange={handleInput} value={state.password}/>
          </InputItem>
          <InputItem>
            REPETIR CONTRASENA
            <input type="password"  name="passwordRepeat" onChange={handleInput}/>
          </InputItem>
          <Button mainConnect onClick={handleChangePassword}>Cambiar</Button>
        </div>
      )}
      {emptyTextError && <DatosInvalidos>Los campos no pueden estar vacios</DatosInvalidos>}
      {passwordLengthError && <DatosInvalidos>La contraseña tiene que tener por lo menos 6 characters</DatosInvalidos>}
      {wrongPassword && <DatosInvalidos>Las contraseñas ingresadas son diferentes</DatosInvalidos>}
      {resetFailed && (
        <>
          <DatosInvalidos>Los datos ingresados no son válidos. Probá de nuevo o crea una cuenta</DatosInvalidos>
          <Button createAccount onClick={() => history.push(`/register`)}>CREAR CUENTA</Button>
        </>
      )}
      {resetSuccess && (
        <>
          <p>El cambio de contraseña fue exitoso. Logeate de nuevo</p>
          <Button smallBlueButton onClick={() => history.push(`/`)}>Ingresa</Button>
        </>
      )}
    </Layout>
    </>
  )
}

export default PasswordChange