import styled from 'styled-components'

const Container = styled.div`
  width: 70%;
  margin: 0 auto;
  text-align: center;
  color: #0069b7;
  font-size: .95em;
  max-width:500px;
  padding-top:1em;
`

const InputItem = styled.label`
  display:flex;
  flex-direction:column;
  margin:1.25em 0;
  font-weight:600;
  font-size:.75em;

input {
  border: 1px solid #40daf0;
  border-radius: 50px;
  padding: .5em;
  margin-top: .5em;
  text-align:center;
}
`
const DatosInvalidos = styled.p`
  color:red;
`

export {
  InputItem,
  Container,
  DatosInvalidos
}