import React from 'react'
import styled from "styled-components"
import logo from "../images/white-logo.png"
import { Container } from '../styles/formStyles'
import { Header, ImageWrapperHeader, Title } from "../styles/LayoutStyles"
import Button from './Button'
import { MainFooter, LoginFooter } from './Footer'
import { useHistory } from 'react-router-dom';
import whitePilarLogo from "../images/pilar-logo-white.svg"


export function Layout(props) {
  let history = useHistory();
  return (
    <>
    {props.main ? (
      <Header>
      <div style={{width:"80%", maxWidth: "505px", margin: "0 auto"}}>
        <ImageWrapperHeader>
          <img src={logo} alt="c360" />
          <img src={whitePilarLogo} alt="pilar"/>
        </ImageWrapperHeader>
        <h2>Bienvenidos al portal 360</h2>
        <p>Mediante su cuenta de Ciudadanos 360 usted podrá ingresar a todos los servicios que brinda la Municipalidad de Pilar obteniendo todos los beneficios que los pilarenses se merecen</p>
        <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
        <Button smallBlueButton onClick={() => history.push(`/login`)}>Ingresar</Button>
        </div>
      </div>
      </Header>
    ):(
      <Header>
        <ImageWrapperHeader>
          <img src={logo} alt="c360" />
        </ImageWrapperHeader>
        <Title>{props.title}</Title>
      </Header>
    )}
    <Container>
        {props.children}
    </Container>
    <MainFooter />
    </>
  )
}

export function LayoutLogin(props) {
  return (
    <>
    <Header>
      <ImageWrapperHeader>
        <img src={logo} alt="c360" />
      </ImageWrapperHeader>
      <Title>{props.title}</Title>
    </Header>
    <Container>
      {props.children}
    </Container>
    <LoginFooter login={props.login} />
    </>
  )
}