import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import MaskedInput from 'react-maskedinput'

import {InputItem, DatosInvalidos } from "../styles/formStyles"
import  Button from "../components/Button"
import { Layout } from "./Layout"
import { registerUser } from '../helpers/api'
import styled from "styled-components"


const HelperText = styled.div`
  margin: -1em 0 1em 0;
  padding: 0;
  font-size: .75em;
`

function GestionarCuenta(props) {
  const [ form1, setForm1 ] = useState(true)
  const [ form2, setForm2 ] = useState(false)
  const [ emptyTextError, setEmptyTextError ] = useState(false)
  const [ wrongPassword, setWrongPassword ] = useState(false)
  const [ registrationFailed, setRegistrationFailed ] = useState(false)
  const [ backButton, setBackButton ] = useState(false)
  const [ phoneInputError, setPhoneInputError ] = useState(false)

  const [ state, setState ] = useState({
    nombre:"",
    apellido: "",
    dni: "",
    fechaNacimiento: false,
    email: "",
    celular: "",
    password: "",
    passwordRepeat: ""
  })

  let history = useHistory()

  const handleContinue = e => {
    e.preventDefault()
    // let fieldsValidated = validateInput()
    // if(fieldsValidated) {
      setForm1(false);
      setForm2(true);
    // }
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    setEmptyTextError(false)
    setWrongPassword(false)
    setPhoneInputError(false)
    setState( prevState => ({
      ...prevState,
      [name]:value
    }))
  }
  const handleRegisterSubmit = e => {
    e.preventDefault()
    let passwordsMatched = validatePasswordMatch() 
      history.push(`/hola`) 
  }


  const validateInput = () => {
    let formIsValid = true;
    if(state.nombre.length < 2 || state.apellido.length < 2 || state.dni < 2 || state.fechaNacimiento === false) {
      formIsValid = false;
      setEmptyTextError(true)
    } 
    return formIsValid;
  }

  const validatePhone = () => {
    let phoneIsValid = true;
    if(state.celular.length < 10 || state.celular.length > 10) {
      setPhoneInputError(true)
      phoneIsValid = false
    }
    console.log("phone is valid", phoneIsValid)
    return phoneIsValid
  }
  const validatePasswordMatch = () => {
    let passwordsValid = true
    if(state.password.length < 1 || state.passwordRepeat.length < 1) {
      setEmptyTextError(true);
      setWrongPassword(false)
      passwordsValid = false;
    }
    if(state.password.length > 1 && state.passwordRepeat.length > 1 && state.password !== state.passwordRepeat) {
      passwordsValid = false;
      setWrongPassword(true);
      setEmptyTextError(false);
    }
    console.log("password is valid", passwordsValid)
    return passwordsValid;
  }

  const goBackToForm1 = () => {
    setForm1(true);
    setForm2(false);
    setRegistrationFailed(false);
  }


  return (
    <Layout title={"EDITAR PERFIL C360"}>
      {form1 && (
        <form>
          <InputItem>
            NOMBRE
            <input type="text"  name="nombre" onChange={handleInput} required />
          </InputItem>
          <InputItem>
            APELLIDO
            <input type="text"  name="apellido" onChange={handleInput}/>
          </InputItem>
          <InputItem>
            DNI
            <input type="text"  name="dni" onChange={handleInput} required="true" data-validation="required" pattern="[0-9]*" maxLength="8"/>
          </InputItem>
          <InputItem>
            FECHA DE NACIMIENTO
            <MaskedInput mask="11/11/1111" name="fechaNacimiento" placeholder="dd/mm/yyyy" onChange={handleInput}/>
          </InputItem>
          {emptyTextError && <DatosInvalidos>Los campos no pueden ser vacios</DatosInvalidos>}
          <Button secondaryBlueButton type="submit" onClick={handleContinue}>SIGUIENTE</Button>
        </form>
      )}
      {form2 && (
        <form>
          <InputItem>
            CORREO ELECTRONICO
            <input type="email"  name="email" onChange={handleInput}/>
          </InputItem>
          <InputItem>
            CELULAR
            {/* <input type="tel"  name="celular" onChange={handleInput} />  */}
            <input onChange={handleInput} type="number" name="celular" required="" data-validation="required" pattern="[0-9]*" maxlength="15"/>
          </InputItem>
          <HelperText>Ingresar el número sin 0 ni 15, por ejemplo 1155667788</HelperText>
          <InputItem>
            CONTRASENA
            <input type="password"  name="password" onChange={handleInput}/>
          </InputItem>
          <InputItem>
            REPETIR CONTRASENA
            <input type="password"  name="passwordRepeat" onChange={handleInput}/>
          </InputItem>
          {wrongPassword && <DatosInvalidos>Las contraseñas ingresadas son diferentes</DatosInvalidos>}
          {emptyTextError && <DatosInvalidos>Las contraseñas no pueden ser vacios</DatosInvalidos>}
          {phoneInputError && <DatosInvalidos>El campo de celular es obligatorio, lo necesitaremos para recordarte la contraseña</DatosInvalidos>}
          <Button secondaryBlueButton onClick={handleRegisterSubmit}>Registar</Button>
          {registrationFailed && (
            <>
              {/* <DatosInvalidos>El ciudadano ya fue dado de alta</DatosInvalidos> */}
              <DatosInvalidos>Los datos ingresados no son válidos</DatosInvalidos>
              <Button goBackButton onClick={goBackToForm1}>Guardar</Button>
            </>
            )}
        </form>
      )}
  </Layout>
  )
}

export default GestionarCuenta