import React from 'react'
import { Header, ImageWrapperHeader } from "../styles/LayoutStyles"
import Button from './Button'
import { Layout } from './Layout'
import { useHistory } from 'react-router-dom'
import icon1 from "../images/icon1.png"
import icon2 from "../images/icon2.png"
import icon3 from "../images/icon3.png"
import icon4 from "../images/icon4.png"
import icon5 from "../images/icon5.png"



function LandingPage() {
  const history = useHistory()
  return(
   <Layout main>
     <div>
       <h3>Cuidadanos 360</h3>
       <p>A traves de este portal usted podrá realizar acciones en las siquientes categorías:</p>
     </div>
     <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", alignItems: "baseline", margin: "4em 0 8em 0"}}>
       <div style={{width:"50px", margin:"0 1em"}}>
          <img src={icon1} alt="icon" style={{width:"100%"}}/>
          <p>Educación</p>
       </div>
       <div style={{width:"50px", margin:"0 1em"}}>
          <img src={icon2} alt="icon" style={{width:"100%"}}/>
          <p>Salud</p>
       </div>
       <div style={{width:"50px", margin:"0 1em"}}>
          <img src={icon3} alt="icon" style={{width:"100%"}}/>
          <p>Cultura</p>
       </div>
       <div style={{width:"50px", margin:"0 1em"}}>
          <img src={icon4} alt="icon" style={{width:"100%"}}/>
          <p>Seguridad</p>
       </div>
       <div style={{width:"50px", margin:"0 1em"}}>
          <img src={icon5} alt="icon" style={{width:"100%"}}/>
          <p>Transporte</p>
       </div>
     </div>
     <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
       <Button smallBlueButton onClick={() => history.push(`/register`)}>Crear Cuenta</Button>
       <Button smallBlueButton onClick={() => history.push(`/gestionar`)}>Gestionar Cuenta</Button>
     </div>
   </Layout>
  )
}

export default LandingPage