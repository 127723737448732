import styled from "styled-components"
import img from "../images/background1.png"

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  ${'' /* max-width: 650px; */}
  margin: 0 auto;
`
const Header = styled.header`
  background: url(${img});
  background-size: cover;
  width:100%;
  padding:1em 0;
  color:white;
  text-align:center;
`
const ImageWrapperHeader = styled.div`
  margin: 0 auto;
  max-width: 200px;
  width: 50%;
  
  img{
      width:100%;
    }
`
const ImageWrapperFooter1 = styled.div`
  width: 35%;
  max-width: 120px;
  margin:0 auto;
  img {
    width:100%;
  }
`

const ImageWrapperFooter2 = styled.div`
  width:70%;
  margin: 0 auto;
  max-width: 250px;
  img {
    width:100%;
  }
`

const Title = styled.div`
  font-weight: 800;
  text-align: center;
  background: white;
  padding: 0.5em;
  border-radius: 45px;
  color: #0069b7;
  max-width: 150px;
  margin: 0 auto;
  font-size: .80em;
`

const Footer = styled.div`
  width:100%;
`

export {
  AppWrapper,
  Header,
  ImageWrapperHeader,
  ImageWrapperFooter1,
  ImageWrapperFooter2,
  Title,
  Footer
}