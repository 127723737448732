import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { getHashRequest, iniciarSesion } from "../helpers/api"
import { LayoutLogin } from "./Layout"
import Button from "./Button"
import Spinner from 'react-bootstrap/Spinner'

import styled from "styled-components"
import { MainConnectButton, SecondaryBlueButton } from "../styles/ButtonStyles"
import { InputItem, Container, DatosInvalidos  } from "../styles/formStyles"
import HolaJuan from './HolaJuan';
import Graphs from './Graphs';

//30149228
//Favio1982123*

const SpinnerWrapper = styled.div`
  width:100px;
  margin:0 auto;
  
  img {
    width:100%;
  }
  `

const ForgotPassword = styled.a`
  font-size: .75em;
  font-weight: 700;
  &:hover {
      cursor:pointer;
    }
`

function LoginForm(props) {
  const [ state, setState ] = useState({
    dni: "",
    password: ""
  })
  const [ loggedIn, setLoggedIn ] = useState(false)
  const [ emptyInputError, setEmptyInputError ] = useState()
  const [ loginFailed, setLoginFailed ] = useState(false)
  const [ hashRequest, setHashRequest ] = useState()

  let history = useHistory();
   
  const handleInput = (e) => {
    const { name, value} = e.target
    setEmptyInputError(false)
    setLoginFailed(false)
    setState( prevState => ({
      ...prevState,
      [name]:value
    }))
  }
  const handleLogin = () => {
    let inputValidated = validateInput()
    if(inputValidated) {
        setLoggedIn(true)
        history.push("/graphs")
    }
  }

  function validateInput() {
    let formIsValid = true;
    if(state.dni.length < 1 || state.password.length < 1) {
      formIsValid = false;
      setEmptyInputError(true)
    }

    return formIsValid;
  }
  return(
    <>
    <LayoutLogin title={"LOGIN"} login={true}>
        {!loggedIn && (
          <>
          <div>
          <InputItem>
            DNI
            <input type="number"  name="dni" onChange={handleInput} value={state.dni}/>
          </InputItem>
          <InputItem>
            CONTRASEÑA
            <input type="password"  name="password" onChange={handleInput} value={state.password}/>
          </InputItem>
          {loginFailed && <DatosInvalidos>Datos invalidos</DatosInvalidos>}
          {emptyInputError && <DatosInvalidos>Los campos no pueden estar vacios</DatosInvalidos>}
          <Button mainConnect onClick={handleLogin}>CONECTARSE</Button>
          {/* <ForgotPassword onClick={() => history.push("/password")}>OLVIDASTE TU CONTRASEÑA</ForgotPassword> */}
          </div>
          </>
        )}
        {loggedIn &&  (
         <Graphs />
        )}
        
    </LayoutLogin>
    </>
  )
}

export default LoginForm