import axios from 'axios'

const api = axios.create({
  baseURL: 'https://api.ciudadanos360.com'
})

export const getHashRequest = (payload) => api.post('/login/GetHashRequest', payload)

export const iniciarSesion = (payload) => api.post('/login/IniciarSesion', payload)

export const registerUser = (payload) => api.post('/login/Registrar', payload)

export const SMSCambiarPassword = (payload) => api.post('/login/SMSCambiarPassword', payload)

export const SMSVerificarCodigo = (payload) => api.post('/login/SMSVerificarCodigo', payload)

export const cambiarPassword = (payload) => api.post('/login/SMSVerificarCodigo', payload)

const apis = {
  getHashRequest,
  iniciarSesion,
  registerUser,
  SMSCambiarPassword,
  SMSVerificarCodigo,
  cambiarPassword
}

export default apis