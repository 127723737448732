import React from 'react'
import { ImageWrapperFooter1, ImageWrapperFooter2} from "../styles/LayoutStyles"
import Button from "./Button"
import logoFooter from "../images/footer.png"
import logo from "../images/color-logo.png"
import styled from "styled-components"
import { useHistory } from 'react-router-dom'

const Footer = styled.div`
  margin: 1em 0;
  background:white;
`

const ImgWrapper = styled.div`
  width: 35%;
  margin: 0 auto;
  max-width: 140px;
  img {
    width:100%;
  }
`
const NoCuenta = styled.p`
  color: #0a74be;
  font-weight: 900;
  margin-top: 3em;
  font-size: .75em;
  margin-bottom: 0;
`

const FooterWrapper = styled.div`
  text-align: center;
  margin: 1em 0;
  background:White;
`

export function MainFooter() {
  return (
    <Footer>
      <ImageWrapperFooter1>
        <img src={logo} alt="C360" />
      </ImageWrapperFooter1>
      <ImageWrapperFooter2>
          <img src={logoFooter} alt="argentina Pilar Unidas"/>
      </ImageWrapperFooter2>
     </Footer>
  )
}

export function LoginFooter(props) {
  const history = useHistory()
  return(
    <FooterWrapper style={{textAlign:"center"}}>
      {props.login && <NoCuenta>NO TENÉS CUENTA?</NoCuenta> }
      <ImgWrapper>
        <img src={logo} alt="c360"/>
      </ImgWrapper>
      <Button smallBlueButton onClick={() => history.push(`/register`)}>CREAR CUENTA</Button>
      <ImageWrapperFooter2>
        <img src={logoFooter} alt="argentina Pilar Unidas"/>
      </ImageWrapperFooter2>
    </FooterWrapper>
  )
}

// export default {
//    MainFooter,
//    LoginFooter
// }