import {createGlobalStyle} from "styled-components";
import background from "../images/whitebackgroundhorizontal.png"

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    margin: 0;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #0069b7;
    text-align: left;
    height: 100%;
    margin: 0;
    ${'' /* background:white; */}
    background: url(${background}) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #root {
    height:100%;
  }
  #background-video {
    width:100%;
  }
`