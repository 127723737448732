import React from 'react'
import { Layout } from './Layout'
import ConectarseButton from "../styles/ButtonStyles"
import Button from "./Button"

function HolaJuan() {
  return (
    <>
    <Layout title={"HOLA JUAN"}>
      <div style={{textAlign:"center"}}>Bienvenidos!</div>
      {/* <Button mainConnect onClick={() => alert("connectado")}>Conectarse</Button>
      <Button blue={true} onClick={()=> alert("Blue")}>i am blue</Button>
      <Button pink={true} onClick={() => alert("pink button coming in hot")}>I AM PINK</Button> */}
    </Layout>
    </>
  )
}

export default HolaJuan