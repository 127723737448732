import React, { useContext } from 'react'
// import BoxHeader from '../../../components/app-layout/box-layout/BoxHeader'
// import StackedBarHorarios from '../../../components/charts/StackedBarHorarios'
// import { DashboardContext } from '../../../contexts/DashboardContext';
// import NoDataMessage from '../../../components/app-layout/box-layout/NoDataMessage'
import StackedBarHorarios from "./Bar"
import { Layout } from './Layout'
import { Line } from 'react-chartjs-2';

const Graphs = () => {
  let horariosBarData ={
    labels:[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    datasets: [
      {
        label:"logins",
        data:[25, 10, 19, 4, 56, 47, 41, 58, 19, 25, 10, 19, 4, 56, 47, 41, 58, 19, 4, 56, 47, 41, 58, 19 ],
        backgroundColor: '#229fda',
        borderColor: '#229fda',
        borderWidth: 20,
      },
      {
        label:"logins",
        data:[],
        backgroundColor: '#3e4145',
      }
    ]
  }
  let  connectionsPerDateData = {
    labels:["01-01-2018", "02-01-2018", "03-01-2018", "04-01-2018", "05-01-2018", "06-01-2018", "07-01-2018", "08-01-2018", "09-01-2018", "10-01-2018", "11-01-2018", "12-01-2018", "13-01-2018", "14-01-2018", "15-01-2018", "16-01-2018", "17-01-2018", "18-01-2018", "19-01-2018", "20-01-2018", "21-01-2018", "22-01-2018", "23-01-2018", "24-01-2018", "25-01-2018", "26-01-2018", "27-01-2018", "28-01-2018", "29-01-2018", "30-01-2018", "31-01-2018"],
    datasets: [
      {
        label:"conexiones",
        data:[0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
        borderColor: ['#44494e'],
        backgroundColor: ['rgba(69, 74, 79, 0.13)'],
      },
      {
        label:"logins",
        data:[0, 2, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 6, 0, 0, 0, 0],
        borderColor: ['#229fda'],
        backgroundColor: ['rgba(34, 158, 216, 0.14)']
      }
    ]
  }
  let dispositivosData ={
    labels:['smartphones', 'tablets', 'pc/mac', 'otro'],
    datasets: [
      {
        label:"logins",
        data:[54, 97, 78, 5],
        backgroundColor: '#229fda',
        borderColor: '#229fda',
        borderWidth: 20,
      },
      {
        label:"logins",
        data:[],
        backgroundColor: '#3e4145',
      }
    ]
  }

  return (
    <Layout title={"BIENVENIDOS"}>
      <div classNameName="horarios-graph">
        <h3>Hourly Logins</h3>
        <StackedBarHorarios data={horariosBarData}/>
      </div>

      <div>
        <h3>Conexiones por fecha</h3>
        <Line
          data={connectionsPerDateData}
          height={210}
          width={100}
          options={{ 
            maintainAspectRatio: false, 
            legend:{display:false}
            }}
        />
      </div>

      <div classNameName="horarios-graph">
        <h3>Dispositivos</h3>
        <StackedBarHorarios data={dispositivosData}/>
      </div>
    </Layout>
  )
}

export default Graphs
