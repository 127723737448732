import React, { useEffect, useState } from 'react'
import { GlobalStyle } from "./styles/globalStyle"
import { AppWrapper } from "./styles/LayoutStyles"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import RegistrationForm from "./components/RegistrationForm"
import LoginForm from './components/LoginForm'
import LoginOptions from './components/LoginOptions'
import Home from "./components/Home"
import HolaJuan from './components/HolaJuan';
import PasswordChange from './components/PasswordChange'
import { getHashRequest } from "./helpers/api"
import LandingPage from './components/LandingPage';
import GestionarCuenta from './components/GestionarCuenta'
import Graphs from './components/Graphs';

function App() {
  const [ hashRequest, setHashRequest ] = useState()

  useEffect(() => {
    //GET PARAMS FROM URL AND SAVE IT IN CONTEXT PROBABLY
    
    const url_string = new URL(window.location.href)
    const url = process.env.REACT_APP_URL ? new URL(process.env.REACT_APP_URL) : new URL(url_string.href)
    let searchParams = new URLSearchParams(url.search)
    let token = searchParams.get("token") 
    setHashRequest(token)   
    
    console.log("url_string: ", url_string)
    console.log("url: ", url)
    console.log("searchParams: ", searchParams)

    let mounted = true
   
  },[])

  console.log("hashrequest: ", hashRequest)

  return (
    <AppWrapper>
      <GlobalStyle />
      {/* <RegistrationForm /> */}
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/login">
            <LoginForm hashRequest={hashRequest}/>
          </Route>
          <Route path="/register">
            <RegistrationForm hashRequest={hashRequest} />
          </Route>
          <Route path="/gestionar">
            <GestionarCuenta hashRequest={hashRequest} />
          </Route>
          <Route path="/password">
            <PasswordChange hashRequest={hashRequest} />
          </Route>
          <Route path="/graphs">
            <Graphs hashRequest={hashRequest} />
          </Route>
          <Route path="/hola">
            <HolaJuan />
          </Route>
        </Switch>
      </Router>
    </AppWrapper>
  )
}

export default App