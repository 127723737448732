import React from 'react'
import Layout from "../components/Layout"
import background from "../images/background1.png"
import logo from "../images/white-logo.png"
import styled from "styled-components"
import { ConectarseButton } from "../styles/ButtonStyles"

const ImageWrapper = styled.div`
  background: url(${background}) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height:100%;
  text-align:center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LogoWrapper = styled.div`
  width:75%;
  margin:0 auto;
  max-width: 315px;
  img{
    width:100%
  }
`

function Home() {
  return (
    <>
      <ImageWrapper>
        <LogoWrapper>
          <img src={logo} alt="C360" />
        </LogoWrapper>
        <ConectarseButton>Conectarse</ConectarseButton>
      </ImageWrapper>
    </>
  )
}

export default Home