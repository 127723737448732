import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
    outline: 0;
    border: 0;
    &:hover {
      cursor:pointer;
    }
  ${({ mainConnect}) => mainConnect && `
    background: #0069b7;
    border: none;
    color: white;
    padding: 1em;
    border-radius: 45px;
    font-weight: 600;
    margin: 1em auto;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
  `}  

  ${({smallBlueButton}) => smallBlueButton && `
    background: #0069b7;
    border: 0;
    color: white;
    margin-top: 1em;
    margin-bottom:1em;
    padding: .5em 0;
    border-radius: 45px;
    width:150px;
  `}
  ${({secondaryBlueButton}) => secondaryBlueButton && `
    background: #0069b7;
    border: 0;
    color: white;
    margin-top: 1em;
    padding: .5em 2.5em;
    border-radius: 45px;
    max-width: 300px;
    margin-bottom:1em;
  `}
  ${({goBackButton}) => goBackButton && `
    font-weight: 700;
    background: #bfb5b5;
    width: 100px;
    margin: 0 auto;
    color: white;
    padding: .25em;
  `}
  ${({createAccount}) => createAccount && `
    outline: 0;
    border: 0;
    font-weight: 700;
    background: #bfb5b5;
    width: 140px;
    margin: 0 auto;
    color: white;
    padding: 0.5em 1em;
    border-radius: 45px;
  `}
`;

function Button({children, ...props}) {
  return (
    <StyledButton {...props}>{children}</StyledButton>
  )
}

export default Button